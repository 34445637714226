<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-07-11 13:12:19
 * @ Description: Fixer's Facebook redirect page.
 -->

<template>
    <div
        style="background-color: #F9FBFD; height: 100vh; display: flex; align-items: center;"
    >
        <v-row
            align="center"
        >
            <v-col
                cols="12"
                class="text-center rob-40-m py-0"
                style="color: rgba(136, 138, 141);"
            >
                Authenticating
            </v-col>
            <v-col
                cols="12"
                class="text-center"
            >
                <v-progress-circular
                    indeterminate
                    color="#FFA858"
                />
            </v-col>
            <v-col
                cols="12"
                class="text-center rob-20-m pt-0"
                style="color: rgba(136, 138, 141);"
            >
                Please wait...
            </v-col>
        </v-row>

        <landing-email-required-overlay-component
            :showLandingEmailRequiredOverlayBoolean="showLandingEmailRequiredOverlayBoolean"
            @toggleLandingEmailRequiredOverlayComponent="toggleLandingEmailRequiredOverlayComponent"
            @cancelAuthentication="cancelAuthentication"
        />
    </div>
</template>
<script>
    import { GeneralLoginController, FacebookController, GeneralSignupController } from '@/logic/controller/index.controller.js'
    import LandingEmailRequiredOverlayComponent from '@/overlays/landing/LandingEmailRequiredOverlayComponent.vue'

    export default {
        name: 'GeneralGoogleAuthenticatePage',

        components: {
            LandingEmailRequiredOverlayComponent
        },

        data () {
            return {
                generalLoginController: null,
                facebookController: null,
                showLandingEmailRequiredOverlayBoolean: false,
                email: '',
                facebookID: null,
                generalSignupController: null
            }
        },

        async beforeMount () {
            this.generalLoginController = new GeneralLoginController()
            this.facebookController = new FacebookController()
            this.generalSignupController = new GeneralSignupController()
        },

        async mounted () {
            try {
                var code = this.$route.params.code

                await this.googleAuthenticate(code)
            } catch (error) {
                this.$store.commit('setSnackbar', {
                    text: error,
                    show: true
                })
            }
        },

        methods: {
            async googleAuthenticate (tokens) {
                try {
                    if (tokens) {
                        tokens = {
                            accessToken: tokens,
                            idToken: '',
                            refreshToken: ''
                        }

                        const loginCredentials = {
                            email: null,
                            password: null,
                            google_auth_code: tokens
                        }

                        const response = await this.generalLoginController.authenticate(loginCredentials)

                        if (response.google_id) {
                            const signupData = {
                                email: response.email,
                                password: response.google_id,
                                type: 'google'
                            }

                            this.$http.token = response.token
                            this.$store.commit('setSignupData', signupData)
                            this.$router.push('/signup')
                        } else this.setLoggedInUser(response)
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async toggleLandingEmailRequiredOverlayComponent (value, isContinue, email) {
                this.showLandingEmailRequiredOverlayBoolean = value
                if (isContinue) {
                    try {
                        await this.generalSignupController.createSignupEmail(email, this.facebookID)

                        this.$store.commit('setEmailVerificationOverlay', true)

                        this.email = ''
                        this.facebookID = null
                    } catch (error) {
                        this.$store.commit('setSnackbar', {
                            text: error,
                            show: true
                        })
                    }
                }
            },

            cancelAuthentication () {
                this.$router.push('/')
            },

            async setLoggedInUser (response) {
                this.$http.token = response.token

                // delete response.token
                await this.$store.commit('user/setUserData', response)
                await this.$store.commit('setNewUser', false)

                this.$router.push(`/dashboard`)
                window.gtag('event', 'login')
            }
        }
    }
</script>
<style scoped>
    @import url('../../assets/style/style.fonts.css');
</style>
